.footer {
    background-color: #21ba45;
    margin-top: auto;
    min-height: 20vh;
    max-height: 30vh;
}

.footer-content {
    color: white;
}

.footer-grid {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
}

/*Small devices (landscape phones, 576px and up)*/
@media (max-width: 576px) {
    .footer {
        min-height: 10vh;
        max-height: 40vh;
    }
}

/*Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) {
    .footer {
        min-height: 10vh;
        max-height: 40vh;
    }
}

/*Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
    .footer {
        min-height: 10vh;
        max-height: 30vh;
    }
}

/*Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {
    .footer {
        min-height: 10vh;
        max-height: 30vh;
    }
}

/*X-Large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
    .footer {
        min-height: 10vh;
        max-height: 30vh;
    }
}

/*XX-Large devices (larger desktops, 1400px and up)*/
@media (min-width: 1400px) {
    .footer {
        min-height: 5vh;
        max-height: 20vh;
    }
}
