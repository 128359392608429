.navbar {
    color: #fff;
    max-height: 10vh;
}

.header {
    font-size: 1.2em; 
    padding-left: 0px;
}

.image {
    margin-right: 1.5em;
}

/*Small devices (landscape phones, 576px and up)*/
@media (max-width: 576px) {
    .navbar {
        max-height: 20vh;
    }
}

/*Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) {
    .navbar {
        max-height: 20vh;
    }
}

/*Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
    .navbar {
        max-height: 10vh;
    }
}

/*Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {
    .navbar {
        max-height: 10vh;
    }
}

/*X-Large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
    .navbar {
        max-height: 10vh;
    }
}

/*XX-Large devices (larger desktops, 1400px and up)*/
@media (min-width: 1400px) {
    .navbar {
        min-height: 5vh;
        max-height: 10vh;
    }
}
