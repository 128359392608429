.app-div {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    word-wrap: break-word;
    word-break: break-word;
}

.app-container {
    margin-top: 2.5vh;
    margin-bottom: 5vh;
}

.link {
    text-decoration: underline;
    color: white;
}

.link:link, .link:hover, .link:visited, .link:active {
    text-decoration: underline;
    color: white;
}

.card-header {
    min-height: 20px !important;
    max-height: 40px !important;
    color: #21ba45 !important;
}

.sub-title {
    float: left;
    color: #232323 !important;
}

.check-icon {
    color: #21ba45 !important;
}

.info-icon-sub-title {
    margin-left: 3px;
    color : #21BA44;
}

.margin-bottom-10 {
    margin-bottom: 10px !important;
}

.disabled-component {
    pointer-events: none;
}

.pie-chart-card {
    min-height: 5vh !important;
    max-height: 36vh !important;
}

.pie-chart-card-content {
    min-height: 5vh !important;
    max-height: 36vh !important;
    overflow: auto !important;
}

.text-align-center {
    text-align: center;
}

.text-align-left {
    text-align: left !important;
}

.submit-button {
    margin-top: 20px !important;
    font-size: 1.2em !important;
    min-width: 180px;
    min-height: 40px;
}

.buttons-div {
    display: block;
    float: right;
    margin-bottom: 10px
}

.hidden-item {
    visibility: hidden;
}

.bug-button {
    color: #DD7A30;
    float: right;
}

.message-box {
    text-align: left;
    min-height: 80px !important;
    max-height: 200px !important;
}

.loader-segment {
    margin-top: 100px;
    width: 100%;
}

.ff-modal {
    top: unset !important;
    bottom: unset !important;
    left: unset !important;
    right: unset !important;
}
